import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('DialogFormWrapper',{scopedSlots:_vm._u([{key:"form",fn:function(){return [_c(VForm,{ref:"exportOrders",staticClass:"custom-form pt-2",attrs:{"id":"exportOrders"},on:{"submit":function($event){$event.preventDefault();return _vm.exportOrders.apply(null, arguments)}}},[_c(VRow,[_c(VCol,{staticClass:"pt-8",attrs:{"cols":"12"}},[_c(VAutocomplete,{staticClass:"chips-select",attrs:{"outlined":"","items":_vm.availableParameters,"label":"Wybierz dane","item-value":"name","multiple":"","rules":[_vm.rules.required],"placeholder":"Wybierz dane","append-icon":"mdi-chevron-down"},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var index = ref.index;
return [(index===0)?_c('draggable',_vm._b({attrs:{"list":_vm.headers}},'draggable',_vm.dragOptionsChips,false),_vm._l((_vm.headers),function(header,idx){return _c(VChip,{key:header,attrs:{"small":""},on:{"mousedown":function($event){$event.stopPropagation();},"click":function($event){$event.stopPropagation();}}},[_vm._v(" "+_vm._s(_vm.getHeaderText(header))+" "),_c(VIcon,{staticClass:"ml-2 mr-n1",attrs:{"small":""},on:{"click":function($event){$event.stopPropagation();return _vm.removeParameter(idx)}}},[_vm._v(" mdi-close-circle ")])],1)}),1):_vm._e()]}}]),model:{value:(_vm.headers),callback:function ($$v) {_vm.headers=$$v},expression:"headers"}})],1)],1)],1)]},proxy:true},{key:"submit",fn:function(){return [_c(VBtn,{staticClass:"base-hover",attrs:{"color":"primary","loading":_vm.isProcessing,"disabled":!_vm.headers.length,"type":"submit","form":"exportOrders"}},[_vm._v(" Eksportuj ")])]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }