<template>
  <DialogFormWrapper>
    <template #form>
      <v-form
        id="exportOrders"
        ref="exportOrders"
        class="custom-form pt-2"
        @submit.prevent="exportOrders"
      >
        <v-row>
          <v-col
            cols="12"
            class="pt-8"
          >
            <v-autocomplete
              v-model="headers"
              outlined
              class="chips-select"
              :items="availableParameters"
              label="Wybierz dane"
              item-value="name"
              multiple
              :rules="[rules.required]"
              placeholder="Wybierz dane"
              append-icon="mdi-chevron-down"
            >
              <template #selection="{ index }">
                <draggable
                  v-if="index===0"
                  :list="headers"
                  v-bind="dragOptionsChips"
                >
                  <v-chip
                    v-for="(header, idx) in headers"
                    @mousedown.stop
                    @click.stop
                    small
                    :key="header"
                  >
                    {{ getHeaderText(header) }}
                    <v-icon
                      class="ml-2 mr-n1"
                      small
                      @click.stop="removeParameter(idx)"
                    >
                      mdi-close-circle
                    </v-icon>
                  </v-chip>
                </draggable>
              </template>
            </v-autocomplete>
          </v-col>
        </v-row>
      </v-form>
    </template>
    <template #submit>
      <v-btn
        color="primary"
        class="base-hover"
        :loading="isProcessing"
        :disabled="!headers.length"
        type="submit"
        form="exportOrders"
      >
        Eksportuj
      </v-btn>
    </template>
  </DialogFormWrapper>
</template>

<script>
import DialogFormWrapper from './Partials/DialogFormWrapper'
import rules from '../../utils/validators'
import { getTableOptions } from '../../utils'
import { mapState, mapActions } from 'vuex'
import draggable from 'vuedraggable'

export default {
  components: {
    DialogFormWrapper,
    draggable
  },
  data () {
    return {
      rules,
      headers: [],
      dragOptionsChips: {
        animation: 200,
        group: 'description',
        disabled: false,
        ghostClass: 'ghost',
        sort: true,
      }
    }
  },
  computed: {
    ...mapState({
      invoice: state => state.invoice.entity,
      isProcessing: state => state.invoice.isProcessing,
    }),
    availableParameters() {
      return getTableOptions('orders').parameters
        .filter(header => !['select', 'actions'].includes(header.name))
    }
  },
  mounted () {
    this.headers = this.availableParameters.map(header => header.name)
  },
  methods: {
    ...mapActions({
      exportOrdersSpreadsheet: 'invoice/exportOrdersSpreadsheet',
    }),
    exportOrders() {
      const orderIds = this.invoice.orders.map(order => order.id)
      this.exportOrdersSpreadsheet({ headers: this.headers, orderIds })
    },
    removeParameter(index) {
      this.headers.splice(index, 1)
    },
    getHeaderText(name) {
      return this.availableParameters.find(param => param.name === name)?.text
    },
  }
}
</script>
